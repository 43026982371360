<template>
  <div class="editBox">
    <div class="topBox">
      <div @click="goBack()"></div>
      <div>编辑资料</div>
    </div>
    <div class="formContent">
      <div class="formBox">
        <van-form>
          <van-cell title="头像" is-link>
            <van-uploader
              v-model="fileList"
              accept="image/*"
              :before-read="beforeRead"
              :after-read="afterRead"
              multiple
              :max-count="1"
            >
              <img
                class="hearImg"
                v-if="this.update.headImg === ''"
                src="../../image/myImg/hear.png"
                alt
              />
              <img
                class="hearImg"
                v-if="this.update.headImg != ''"
                :src="update.headImg"
                alt
              />
            </van-uploader>
          </van-cell>
          <van-cell title="昵称" is-link
            ><input
              class="inputName"
              type="text"
              placeholder="请输入8个字以内的昵称"
              v-model="update.nickName"
          /></van-cell>
          <van-cell title="性别">
            <div class="sexBox">
              <van-radio-group v-model="update.sex" direction="horizontal">
                <van-radio name="0">男</van-radio>
                <van-radio name="1">女</van-radio>
              </van-radio-group>
            </div>
          </van-cell>
          <van-cell
            title="生日"
            is-link
            v-model="update.birthday"
            @click="birthdayShow = true"
          />
          <van-popup v-model="birthdayShow" position="bottom">
            <van-datetime-picker
              v-model="update.birthday"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="onCancel"
              @confirm="onConfirm"
            />
          </van-popup>
          <van-cell
            title="所在地"
            is-link
            v-model="update.regionName"
            @click="locationShow = true"
          />
          <van-popup v-model="locationShow" position="bottom">
            <van-cascader
              v-model="update.regionId"
              title="请选择所在地区"
              :options="this.$store.state.optionsList"
              active-color="#1989fa"
              @close="cascaderShow = false"
              @finish="onFinish"
              @change="onCascaderChange"
            />
          </van-popup>
          <div class="briefBox">
            <div class="brieSize">个人简介</div>
            <el-input
              id="inpTextarea"
              type="textarea"
              placeholder="请输入内容"
              v-model="update.desc"
              maxlength="30"
              show-word-limit
            >
            </el-input>
          </div>
          <van-cell title="实名/身份认证" is-link>
            <div
              class="red"
              v-if="update.cardAuth === 0"
              @click="goauthentication()"
            >
              未认证
            </div>
            <div class="blue" v-if="update.cardAuth === 2">已认证</div>
          </van-cell>
        </van-form>
      </div>
    </div>
    <div class="saveBtn" @click="saveEven()">保存</div>
  </div>
</template>

<script>
import { Popup } from "vant";
import { uploadHead, userUpdata } from "../../api/lookAt";
import { Toast } from "vant";
import { Uploader } from "vant";
export default {
  data() {
    return {
      user: this.$route.query.user,
      birthdayShow: false, //选择生日弹框
      maxDate: new Date(),
      minDate: new Date(1971, 0, 1),
      location: "请选择", //所在地
      locationShow: false, //所在地弹框
      options: [], //所在地列表
      fileList: [], //上传头像
      nickname: "", //昵称
      update: {
        headImg: "", //头像
        nickName: "", //昵称
        sex: "", //性别
        birthday: "", //出生日期
        regionName: "", //所在地
        regionId: "", //所在地id
        desc: "", //个人简介
        cardAuth: "", //实名认证 0：否；1：是
      },
    };
  },
  mounted() {
    console.log(this.user, "****");
    this.update.headImg = this.user.headImg;
    this.update.nickName = this.user.nickName;
    this.update.birthday = this.user.birthday;
    this.update.regionName = this.user.regionName;
    this.update.desc = this.user.desc;
    this.update.sex = this.user.sex.toString();
    this.update.regionId = this.user.regionId;
    this.update.cardAuth = this.user.cardAuth;
    console.log(this.$store.state.optionsList, "1111");
    this.options = this.$store.state.optionsList.map((item) => {
      item.text = item.name;
      item.value = item.id;
      if (item.children.length > 0 || item.children != null) {
        item.children.map((i) => {
          i.text = i.name;
          i.value = i.id;
        });
      }
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 取消选择时间
    onCancel() {
      this.birthdayShow = false;
    },
    // 确定选择时间
    onConfirm(date) {
      // var date = new Date(value);
      let yy = date.getFullYear();
      let mm =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      console.log(yy + "-" + mm + "-" + dd);
      this.update.birthday = yy + "-" + mm + "-" + dd;
      this.birthdayShow = false;
    },
    onFinish({ selectedOptions }) {
      this.locationShow = false;
      this.update.regionName = selectedOptions
        .map((option) => option.text)
        .join("/");
    },
    onCascaderChange(value) {
      console.log(value);
      if (value.selectedOptions.length === 2) {
        this.update.regionId = value.selectedOptions[1].id;
      }
    },
    beforeRead(file) {
      // debugger;
      console.log(file);
      if (file.type == "image/png" || file.type == "image/jpg") {
        return true;
      } else {
        Toast("请上传 jpg、png 格式图片");
        return false;
      }
    },
    afterRead(file) {
      let formData = new FormData();
      formData.append("file	", file.file);
      uploadHead(formData).then((res) => {
        console.log(res, "宣传图");
        console.log(this.fileList);
        if (res.data.code === 0) {
          this.update.headImg = res.data.data;
        } else {
          Toast(res.data.msg);
          this.update.headImg = "";
        }
        // this.ruleForm.imgUrl = res.data.data;
      });
    },
    // 保存
    saveEven() {
      userUpdata(this.update).then((res) => {
        console.log(res, "编辑信息");
        if (res.data.code === 0) {
          Toast("保存成功");
          this.$router.go(-1);
        } else {
          Toast(res.data.msg);
        }
      });
    },
    goauthentication() {
      this.$router.push({ path: "/authentication" });
    },
  },
};
</script>

<style scoped>
.editBox {
  height: 1624px;
}
.topBox {
  display: flex;
  align-items: center;
  background: #fff;
  padding-top: 20px;
  box-sizing: border-box;
}
.topBox div:nth-child(1) {
  width: 48px;
  height: 68px;
  background-image: url("../../image/myImg/goBack.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topBox div:nth-child(2) {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 232px;
}
.bcBtn {
  width: 300px;
  font-size: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  text-align: right;
}
.bcBtn1 {
  width: 300px;
  font-size: 28px;
  font-weight: 500;
  color: #5777ed;
  text-align: right;
}
.formBox {
  background: #fff;
  padding-top: 32px;
  box-sizing: border-box;
}
.hearImg {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
/deep/.van-uploader__upload {
  width: 0px;
  height: 0px;
}
/deep/.van-uploader__upload-icon {
  font-size: 0px;
}
.botForm {
  margin-top: 32px;
}
.inputBox {
  margin-top: 32px;
}
/deep/.van-image__img {
  width: 64px;
  height: 64px;
}
/deep/.van-uploader__preview-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
/deep/.van-uploader__preview-delete {
  background-color: transparent;
}
.inputName {
  width: 300px;
  border: none;
  outline: none;
  font-size: 28px;
  font-weight: 400;
  /* color: #97a4b4; */
  text-align: right;
  /* background-color: #f2f3f5; */
}
.sexBox {
  width: 260px;
  margin-left: 140px;
  text-align: right;
}
/deep/.van-radio__label {
  color: #97a4b4;
}
.brieSize {
  font-size: 28px;
  font-weight: 400;
  color: #323233;
  /* margin-left: 32px; */
  margin-top: 32px;

  margin-bottom: 32px;
}
.briefBox {
  width: 680px;
  margin-left: 32px;
  position: relative;
  border-bottom: 1px solid #f6f8fa;
}
/deep/#inpTextarea {
  width: 680px;
  height: 200px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  border: none;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 32px;
}
/deep/.el-input__count {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  position: absolute;
  bottom: 60px;
  right: 15px;
}
.red {
  color: #fa2819;
  font-size: 28px;
}
.blue {
  font-size: 28px;
  color: #0957c3;
}
.saveBtn {
  width: 684px;
  height: 88px;
  background: #0957c3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  text-align: center;
  line-height: 88px;
  font-weight: 600;
  color: #ffffff;
  font-size: 32px;
  position: fixed;
  bottom: 32px;
  left: 32px;
}
</style>
